import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { CometChat } from "@cometchat-pro/chat"
import { config } from './helpers/index';
import Main from './components/main';

// import "./lib/CometChat/resources/styles.scss";

var appID = config.APP_ID;
var region = config.REGION;
var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();

CometChat.init(appID, appSetting).then(() => {
  window.CometChat = CometChat;

  if (CometChat.setSource) {
    CometChat.setSource("ui-kit", "web", "reactjs");

    ReactDOM.render(
      <>
        <Main />
      </>,
      document.getElementById('root-cometchat')
    );
  }
}, error => {
  console.log("Initialization failed with error:", error);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
