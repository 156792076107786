import { CometChat } from '@cometchat-pro/chat';

const env = process.env,
    config = {
        APP_ID: env.REACT_APP_ID,
        REGION: env.REACT_APP_REGION,
        AUTH_KEY: env.REACT_APP_AUTH_KEY
    };

// CometChat Pro Fns
const createUser = (userID, callback) => {
    let newUser = new CometChat.User(userID);
    newUser.setName(getLocalUser(1));

    CometChat.createUser(newUser, config.AUTH_KEY).then(
        user => {
            if (callback) {
                callback(user);
            }
        }, error => {
            console.log("error", error);
        }
    )
}, getLoggedinUser = (callback) => {
    CometChat.getLoggedinUser().then(user => {
        if (callback) {
            callback(user);
        }
    }).catch(error => {
        console.log(error);
    });
}, login = (userID, callback) => {
    CometChat.login(userID, config.AUTH_KEY).then((user) => {
        if (callback) {
            callback(user);
        }
    }, error => {
        callback(null);
        console.log('CometChatLogin Failed', error);
    });
};
// End CometChat Pro Fns

// LocalStorage Fns
const getLocalUser = (index) => {
    const keys = ['uid', 'name'];
    let localUser = localStorage.getItem(':common_store/local_user');
    if (localUser) {
        localUser = JSON.parse(localUser);
        console.log(localUser[keys[index]]);

        return localUser[keys[index]];
    }
};

// SVG and Images
const blueDoubleTick = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAAWdJREFUOBHNUrtKA1EQPXeNRUDxgVqLQj7AjWjAwh8QKyvbuIIgVhYWNhYWdhFBkugPpLOwt7CwSEQLrTQI1roB44Os7nhm4YZdCyWg4MDsmTOzM3dm7gXiMlUWN84B9RjrcouyBQOJOMmmqg0mMJ5S0IjhrzfELmpQWzYZdUJEjGpEOv6wZNSKxagMScEYzDHU63RjIgxwzvOGO67+Swm2OYupeN1sSY650DzxSFFjxi3JXcpgxunDQ8tHc2wA6bqPV06h24NT88zoe4h7Bv10D4bqDTwqajAh2aLsqcNiIvin5McbzVUkHTRwwms642BrX/m3BbjLcbZ/RX3i4JnWC/pDwTWLNZWfLhrf0flyBzKoldW2ki3LPG19jBdVDyNvz5j+CFFn8qVyTdZ/ow64uR2+sXXy25SDWa7do62Pfp9tr0yWZIMnb1tObEtiBN7wLk9Y1ahxkK8umUOOUSFdsLyd+W+MTyDvnh60pF/jAAAAAElFTkSuQmCC',
    greyDoubleTick = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAAWNJREFUOBFjYEAGEhISosh8BgaQCCNMSFJS0gTGZhARETECYbgAMgOuRUxMzAokwQhUGgGkmYD435s3b1aABBn+///PCMJgDskE0Mg0kCYYDTYGZOG/f//kmZiYWIFGr2NkZAx69erVIpJNp5IGmONgNIpfRUVFPVlZWe//+fNHnoWF5eGzZ89uMImLi0cBJXgcHBxYgD6Qtba2vg3yEUgS5CiwCVDj/gkICCz++PFjND8//9I7d+78RHE10BQbkACMRpGkKYeZkOkgz3379s2fm5tb6OvXr4/R+SjBhG6YiooK34cPH8KAcfyLj49vJVCeHRgIocC4/g3iwwNCQUGBA2QysgHAFK0ACj1gQgkApT0pKSk5ZD5MLdgFwLiw+Pv3rx5Q8BPQ9C3ASNL4/fu3EZB97fXr10eAIWsINMQUxodpBtEoXgDaZg2MRG2QBBsb2yFQZAINdwEargTjg+QGFwAAd+eJSUtUxzMAAAAASUVORK5CYII=',
    greyTick = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAARdJREFUOBFjYEAGIiIiUQg+kBeB4EFZYmJiVnDB////M8I5WBmSkpImIAkmmCxQy0MYmwFogxGcQxYDaEIaTCPcKSA3/vv3T56JiYn11atXi2AKBopGdiQLuiNERUU9WVlZD8HEwSElLi4eBZTgcXBwYAGGmOyzZ89uwBTAvQk19p+AgMDiO3fu/IQpQKGBptigCNCFw0ysLSAPfvv2zZ+bm1vo69evj2H64OkFJoCNVlFR4bty5UoCUI6Xj4/vLDY1YDEFBQUOkE3ICiQkJBRAIQhMLAHY0jE8mEGagPFh8ffvXz0g8xMjI+MWFhYWjd+/fxsB2ddev359BNlgGBvFAJgg0DZrYNLUBvHZ2NgOIUcsTM3goQFXzEj1uzh+EwAAAABJRU5ErkJggg==',
    callBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMTYuMjM0IDEyLjI2bC0yLjU0LS4yOWExLjk5IDEuOTkgMCAwIDAtMS42NC41N2wtMS44NCAxLjg0YTE1LjA0NSAxNS4wNDUgMCAwIDEtNi41OS02LjU5bDEuODUtMS44NWMuNDMtLjQzLjY0LTEuMDMuNTctMS42NGwtLjI5LTIuNTJBMi4wMDEgMi4wMDEgMCAwIDAgMy43NjQuMDFoLTEuNzNjLTEuMTMgMC0yLjA3Ljk0LTIgMi4wNy41MyA4LjU0IDcuMzYgMTUuMzYgMTUuODkgMTUuODkgMS4xMy4wNyAyLjA3LS44NyAyLjA3LTJ2LTEuNzNjLjAxLTEuMDEtLjc1LTEuODYtMS43Ni0xLjk4eiIvPjwvZz48L2c+PC9zdmc+',
    detailPaneBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDIwIDE1Ij48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMTYuMzU3IDEwaC00LjcxNGMtLjM1MiAwLS42NDMuMzQtLjY0My43NXMuMjkxLjc1LjY0My43NWg0LjcxNGMuMzUyIDAgLjY0My0uMzQuNjQzLS43NXMtLjI5MS0uNzUtLjY0My0uNzV6bTAtM2gtNC43MTRjLS4zNTIgMC0uNjQzLjM0LS42NDMuNzVzLjI5MS43NS42NDMuNzVoNC43MTRjLjM1MiAwIC42NDMtLjM0LjY0My0uNzVTMTYuNzA5IDcgMTYuMzU3IDd6bTAtM2gtNC43MTRjLS4zNTIgMC0uNjQzLjM0LS42NDMuNzVzLjI5MS43NS42NDMuNzVoNC43MTRjLjM1MiAwIC42NDMtLjM0LjY0My0uNzVTMTYuNzA5IDQgMTYuMzU3IDR6TTE4IDEyYTEgMSAwIDAgMS0xIDFoLTdWMmg3YTEgMSAwIDAgMSAxIDF6TTMgMGEzIDMgMCAwIDAtMyAzdjlhMyAzIDAgMCAwIDMgM2gxNGEzIDMgMCAwIDAgMy0zVjNhMyAzIDAgMCAwLTMtM3oiLz48L2c+PC9nPjwvc3ZnPg==',
    videoCallBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDE4IDEyIj48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMTQgNC41VjFhMSAxIDAgMCAwLTEtMUgxYTEgMSAwIDAgMC0xIDF2MTBhMSAxIDAgMCAwIDEgMWgxMmExIDEgMCAwIDAgMS0xVjcuNWwyLjI5IDIuMjljLjYzLjYzIDEuNzEuMTggMS43MS0uNzFWMi45MWMwLS44OS0xLjA4LTEuMzQtMS43MS0uNzF6Ii8+PC9nPjwvZz48L3N2Zz4=',
    blueFile = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBmaWxsPSIjMzlmIiBkPSJNNiAyYy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02SDZ6bTcgN1YzLjVMMTguNSA5SDEzeiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=',
    roundedPlus = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48Zz48Zz48cGF0aCBmaWxsPSIjODg4ODg4IiBmaWxsLW9wYWNpdHk9Ii40IiBkPSJNMTUgMTFoLTR2NGMwIC41NS0uNDUgMS0xIDFzLTEtLjQ1LTEtMXYtNEg1Yy0uNTUgMC0xLS40NS0xLTFzLjQ1LTEgMS0xaDRWNWMwLS41NS40NS0xIDEtMXMxIC40NSAxIDF2NGg0Yy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxek0xMCAwQzQuNDggMCAwIDQuNDggMCAxMHM0LjQ4IDEwIDEwIDEwIDEwLTQuNDggMTAtMTBTMTUuNTIgMCAxMCAweiIvPjwvZz48L2c+PC9zdmc+',
    sendBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMS40IDE3LjQwNWwxNy40NS03LjQ4YTEgMSAwIDAgMCAwLTEuODRMMS40LjYwNWEuOTkzLjk5MyAwIDAgMC0xLjM5LjkxTDAgNi4xMjVjMCAuNS4zNy45My44Ny45OUwxNSA5LjAwNS44NyAxMC44ODVjLS41LjA3LS44Ny41LS44NyAxbC4wMSA0LjYxYzAgLjcxLjczIDEuMiAxLjM5LjkxeiIvPjwvZz48L2c+PC9zdmc+',
    imageUpload = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iIzM5ZiIgZD0iTTIxIDE5VjVjMC0xLjEtLjktMi0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnpNOC41IDEzLjVsMi41IDMuMDFMMTQuNSAxMmw0LjUgNkg1bDMuNS00LjV6Ii8+PC9zdmc+',
    audioUpload = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iIzM5ZiIgZD0iTTEyIDN2OS4yOGMtLjQ3LS4xNy0uOTctLjI4LTEuNS0uMjhDOC4wMSAxMiA2IDE0LjAxIDYgMTYuNVM4LjAxIDIxIDEwLjUgMjFjMi4zMSAwIDQuMi0xLjc1IDQuNDUtNEgxNVY2aDRWM2gtN3oiLz48L3N2Zz4=',
    videoUpload = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iIzM5ZiIgZD0iTTQgNkgydjE0YzAgMS4xLjkgMiAyIDJoMTR2LTJINFY2em0xNi00SDhjLTEuMSAwLTIgLjktMiAydjEyYzAgMS4xLjkgMiAyIDJoMTJjMS4xIDAgMi0uOSAyLTJWNGMwLTEuMS0uOS0yLTItMnptLTggMTIuNXYtOWw2IDQuNS02IDQuNXoiLz48L3N2Zz4=',
    fileUpload = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBmaWxsPSIjMzlmIiBkPSJNNiAyYy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02SDZ6bTcgN1YzLjVMMTguNSA5SDEzeiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=',
    peopleGrey = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDE4IDE5Ij48Zz48Zz48cGF0aCBmaWxsPSIjODg4ODg4IiBmaWxsLW9wYWNpdHk9Ii41IiBkPSJNMTggMTYuNHYxLjEyNWMwIC42MTktLjUwNiAxLjEyNS0xLjEyNSAxLjEyNUgxLjEyNUExLjEyOCAxLjEyOCAwIDAgMSAwIDE3LjUyNVYxNi40YzAtMi45OTMgNS45OTYtNC41IDktNC41IDMuMDA0IDAgOSAxLjUwNyA5IDQuNXpNNC41IDUuMTVDNC41IDIuNjY0IDYuNTE0LjY1IDkgLjY1czQuNSAyLjAxNCA0LjUgNC41LTIuMDE0IDQuNS00LjUgNC41YTQuNDk5IDQuNDk5IDAgMCAxLTQuNS00LjV6Ii8+PC9nPjwvZz48L3N2Zz4=',
    peopleBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDE4IDE5Ij48Zz48Zz48cGF0aCBmaWxsPSIjMzM5OWZmIiBkPSJNMTggMTYuNHYxLjEyNWMwIC42MTktLjUwNiAxLjEyNS0xLjEyNSAxLjEyNUgxLjEyNUExLjEyOCAxLjEyOCAwIDAgMSAwIDE3LjUyNVYxNi40YzAtMi45OTMgNS45OTYtNC41IDktNC41IDMuMDA0IDAgOSAxLjUwNyA5IDQuNXpNNC41IDUuMTVDNC41IDIuNjY0IDYuNTE0LjY1IDkgLjY1czQuNSAyLjAxNCA0LjUgNC41LTIuMDE0IDQuNS00LjUgNC41YTQuNDk5IDQuNDk5IDAgMCAxLTQuNS00LjV6Ii8+PC9nPjwvZz48L3N2Zz4=',
    callGrey = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDE4IDE5Ij48Zz48Zz48cGF0aCBmaWxsPSIjODg4ODg4IiBmaWxsLW9wYWNpdHk9Ii41IiBkPSJNMTYuMjM0IDEyLjkxbC0yLjU0LS4yOWExLjk5IDEuOTkgMCAwIDAtMS42NC41N2wtMS44NCAxLjg0YTE1LjA0NSAxNS4wNDUgMCAwIDEtNi41OS02LjU5bDEuODUtMS44NWMuNDMtLjQzLjY0LTEuMDMuNTctMS42NGwtLjI5LTIuNTJBMi4wMDEgMi4wMDEgMCAwIDAgMy43NjQuNjZoLTEuNzNjLTEuMTMgMC0yLjA3Ljk0LTIgMi4wNy41MyA4LjU0IDcuMzYgMTUuMzYgMTUuODkgMTUuODkgMS4xMy4wNyAyLjA3LS44NyAyLjA3LTJ2LTEuNzNjLjAxLTEuMDEtLjc1LTEuODYtMS43Ni0xLjk4eiIvPjwvZz48L2c+PC9zdmc+',
    // callBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIwIDIxIj48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMTUgNi42NUg1Yy0uNTUgMC0xLS40NS0xLTFzLjQ1LTEgMS0xaDEwYy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxem0tNCA2SDVjLS41NSAwLTEtLjQ1LTEtMXMuNDUtMSAxLTFoNmMuNTUgMCAxIC40NSAxIDFzLS40NSAxLTEgMXptLTYtNWgxMGMuNTUgMCAxIC40NSAxIDFzLS40NSAxLTEgMUg1Yy0uNTUgMC0xLS40NS0xLTFzLjQ1LTEgMS0xem0xMy03SDJjLTEuMSAwLTEuOTkuOS0xLjk5IDJsLS4wMSAxOCA0LTRoMTRjMS4xIDAgMi0uOSAyLTJ2LTEyYzAtMS4xLS45LTItMi0yeiIvPjwvZz48L2c+PC9zdmc+',
    chatGrey = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIwIDIxIj48Zz48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM4ODg4ODgiIGZpbGwtb3BhY2l0eT0iLjUiIGQ9Ik0xNSA2LjY1SDVjLS41NSAwLTEtLjQ1LTEtMXMuNDUtMSAxLTFoMTBjLjU1IDAgMSAuNDUgMSAxcy0uNDUgMS0xIDF6bS00IDZINWMtLjU1IDAtMS0uNDUtMS0xcy40NS0xIDEtMWg2Yy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxem0tNi01aDEwYy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxSDVjLS41NSAwLTEtLjQ1LTEtMXMuNDUtMSAxLTF6bTEzLTdIMmMtMS4xIDAtMS45OS45LTEuOTkgMmwtLjAxIDE4IDQtNGgxNGMxLjEgMCAyLS45IDItMnYtMTJjMC0xLjEtLjktMi0yLTJ6Ii8+PC9nPjwvZz48L3N2Zz4=',
    chatBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIwIDIxIj48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMTUgNi42NUg1Yy0uNTUgMC0xLS40NS0xLTFzLjQ1LTEgMS0xaDEwYy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxem0tNCA2SDVjLS41NSAwLTEtLjQ1LTEtMXMuNDUtMSAxLTFoNmMuNTUgMCAxIC40NSAxIDFzLS40NSAxLTEgMXptLTYtNWgxMGMuNTUgMCAxIC40NSAxIDFzLS40NSAxLTEgMUg1Yy0uNTUgMC0xLS40NS0xLTFzLjQ1LTEgMS0xem0xMy03SDJjLTEuMSAwLTEuOTkuOS0xLjk5IDJsLS4wMSAxOCA0LTRoMTRjMS4xIDAgMi0uOSAyLTJ2LTEyYzAtMS4xLS45LTItMi0yeiIvPjwvZz48L2c+PC9zdmc+',
    groupGrey = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDIyIDE3Ij48Zz48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM4ODg4ODgiIGZpbGwtb3BhY2l0eT0iLjUiIGQ9Ik0xNiAxMy42NXYyYzAgLjU1LS40NSAxLTEgMUgxYy0uNTUgMC0xLS40NS0xLTF2LTJjMC0yLjY2IDUuMzMtNCA4LTRzOCAxLjM0IDggNHptLTMuMzMtNS4yNGE1Ljk4IDUuOTggMCAwIDAgMC03LjUyYy40Mi0uMTQuODYtLjI0IDEuMzMtLjI0IDIuMjEgMCA0IDEuNzkgNCA0cy0xLjc5IDQtNCA0Yy0uNDcgMC0uOTEtLjEtMS4zMy0uMjR6TTQgNC42NWE0IDQgMCAxIDEgOCAwIDQgNCAwIDAgMS04IDB6bTE4IDl2MmMwIC41NS0uNDUgMS0xIDFoLTN2LTNjMC0xLjY4LS45Ni0yLjk0LTIuMzMtMy44NyAyLjc2LjQgNi4zMyAxLjY5IDYuMzMgMy44N3oiLz48L2c+PC9nPjwvc3ZnPg==',
    groupBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDIyIDE3Ij48Zz48Zz48cGF0aCBmaWxsPSIjMzlmIiBkPSJNMTYgMTMuNjV2MmMwIC41NS0uNDUgMS0xIDFIMWMtLjU1IDAtMS0uNDUtMS0xdi0yYzAtMi42NiA1LjMzLTQgOC00czggMS4zNCA4IDR6bS0zLjMzLTUuMjRhNS45OCA1Ljk4IDAgMCAwIDAtNy41MmMuNDItLjE0Ljg2LS4yNCAxLjMzLS4yNCAyLjIxIDAgNCAxLjc5IDQgNHMtMS43OSA0LTQgNGMtLjQ3IDAtLjkxLS4xLTEuMzMtLjI0ek00IDQuNjVhNCA0IDAgMSAxIDggMCA0IDQgMCAwIDEtOCAwem0xOCA5djJjMCAuNTUtLjQ1IDEtMSAxaC0zdi0zYzAtMS42OC0uOTYtMi45NC0yLjMzLTMuODcgMi43Ni40IDYuMzMgMS42OSA2LjMzIDMuODd6Ii8+PC9nPjwvZz48L3N2Zz4=',
    moreGrey = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIwIDIxIj48Zz48Zz48cGF0aCBmaWxsPSIjODg4ODg4IiBmaWxsLW9wYWNpdHk9Ii41IiBkPSJNNiAxMC42NWMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTIgLjktMiAyLTIgMiAuOSAyIDJ6bTEyIDBjMCAxLjEtLjkgMi0yIDJzLTItLjktMi0yIC45LTIgMi0yIDIgLjkgMiAyem0tNiAwYzAgMS4xLS45IDItMiAycy0yLS45LTItMiAuOS0yIDItMiAyIC45IDIgMnptLTEyIDBjMCA1LjUyMyA0LjQ3NyAxMCAxMCAxMHMxMC00LjQ3NyAxMC0xMC00LjQ3Ny0xMC0xMC0xMC0xMCA0LjQ3Ny0xMCAxMHoiLz48L2c+PC9nPjwvc3ZnPg==',
    moreBlue = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIwIDIxIj48Zz48Zz48cGF0aCAgZmlsbD0iIzMzOTlmZiIgZD0iTTYgMTAuNjVjMCAxLjEtLjkgMi0yIDJzLTItLjktMi0yIC45LTIgMi0yIDIgLjkgMiAyem0xMiAwYzAgMS4xLS45IDItMiAycy0yLS45LTItMiAuOS0yIDItMiAyIC45IDIgMnptLTYgMGMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTIgLjktMiAyLTIgMiAuOSAyIDJ6bS0xMiAwYzAgNS41MjMgNC40NzcgMTAgMTAgMTBzMTAtNC40NzcgMTAtMTAtNC40NzctMTAtMTAtMTAtMTAgNC40NzctMTAgMTB6Ii8+PC9nPjwvZz48L3N2Zz4=',
    notificationBlack = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDE2IDIwIj48Zz48Zz48cGF0aCBmaWxsPSIjMTQxNDE0IiBkPSJNMTUuMjg2IDE1LjI5Yy42My42My4xOCAxLjcxLS43MSAxLjcxSDEuNDA2Yy0uODkgMC0xLjMzLTEuMDgtLjctMS43MUwxLjk5NiAxNFY5YzAtMy4wOCAxLjYzLTUuNjQgNC41LTYuMzJWMmMwLS44My42Ny0xLjUgMS41LTEuNXMxLjUuNjcgMS41IDEuNXYuNjhjMi44Ni42OCA0LjUgMy4yNSA0LjUgNi4zMnY1ek01Ljk5NiAxOGg0YTIgMiAwIDEgMS00IDB6Ii8+PC9nPjwvZz48L3N2Zz4=',
    privacyBlack = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDE4IDIyIj48Zz48Zz48cGF0aCBmaWxsPSIjMTQxNDE0IiBkPSJNOSAxMC45OTJoN2MtLjUzIDQuMTItMy4yOCA3Ljc5LTcgOC45NHYtOC45M0gydi01LjdsNy0zLjExek04LjE5LjM2MmwtNyAzLjExQy40NyAzLjc5MyAwIDQuNTEzIDAgNS4zMDN2NC43YzAgNS41NSAzLjg0IDEwLjc0IDkgMTIgNS4xNi0xLjI2IDktNi40NSA5LTEydi00LjdjMC0uNzktLjQ3LTEuNTEtMS4xOS0xLjgzbC03LTMuMTFjLS41MS0uMjMtMS4xMS0uMjMtMS42MiAweiIvPjwvZz48L2c+PC9zdmc+',
    chatBlack = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48Zz48Zz48cGF0aCBmaWxsPSIjMTQxNDE0IiBkPSJNMTUgNkg1Yy0uNTUgMC0xLS40NS0xLTFzLjQ1LTEgMS0xaDEwYy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxem0tNCA2SDVjLS41NSAwLTEtLjQ1LTEtMXMuNDUtMSAxLTFoNmMuNTUgMCAxIC40NSAxIDFzLS40NSAxLTEgMXpNNSA3aDEwYy41NSAwIDEgLjQ1IDEgMXMtLjQ1IDEtMSAxSDVjLS41NSAwLTEtLjQ1LTEtMXMuNDUtMSAxLTF6bTEzLTdIMkMuOSAwIC4wMS45LjAxIDJMMCAyMGw0LTRoMTRjMS4xIDAgMi0uOSAyLTJWMmMwLTEuMS0uOS0yLTItMnoiLz48L2c+PC9nPjwvc3ZnPg==',
    helpBlack = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48Zz48Zz48cGF0aCBmaWxsPSIjMTQxNDE0IiBkPSJNMTIuMTcgMTAuMTdjLS41LjUxLS44Ni45Ny0xLjA0IDEuNjktLjA4LjMyLS4xMy42OC0uMTMgMS4xNEg5di0uNWEzLjk5NyAzLjk5NyAwIDAgMSAxLjE3LTIuODNsMS4yNC0xLjI2Yy40Ni0uNDQuNjgtMS4xLjU1LTEuOGExLjk5IDEuOTkgMCAwIDAtMS4zOS0xLjUzYy0xLjExLS4zMS0yLjE0LjMyLTIuNDcgMS4yNy0uMTIuMzctLjQzLjY1LS44Mi42NWgtLjNDNi40IDcgNiA2LjQ0IDYuMTYgNS44OGE0LjAwOCA0LjAwOCAwIDAgMSAzLjIzLTIuODNjMS41Mi0uMjQgMi45Ny41NSAzLjg3IDEuOCAxLjE4IDEuNjMuODMgMy4zOC0uMTkgNC40ek0xMSAxN0g5di0yaDJ6TTEwIDBDNC40OCAwIDAgNC40OCAwIDEwczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNS41MiAwIDEwIDB6Ii8+PC9nPjwvZz48L3N2Zz4=',
    reportBlack = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIyIDE4Ij48Zz48Zz48cGF0aCBmaWxsPSIjMTQxNDE0IiBkPSJNMTEuMDAzIDExLjAwMmMtLjU1IDAtMS0uNDUtMS0xdi0yYzAtLjU1LjQ1LTEgMS0xczEgLjQ1IDEgMXYyYzAgLjU1LS40NSAxLTEgMXptMSA0aC0ydi0yaDJ6bS0xMC4yNyAzaDE4LjUzYy43NyAwIDEuMjUtLjgzLjg3LTEuNWwtOS4yNy0xNmEuOTk2Ljk5NiAwIDAgMC0xLjczIDBsLTkuMjcgMTZjLS4zOC42Ny4xIDEuNS44NyAxLjV6Ii8+PC9nPjwvZz48L3N2Zz4=';

export {
    config,

    createUser,
    getLoggedinUser,
    login,

    getLocalUser,

    blueDoubleTick,
    greyDoubleTick,
    greyTick,
    callBlue,
    detailPaneBlue,
    videoCallBlue,
    blueFile,
    roundedPlus,
    sendBlue,
    imageUpload,
    audioUpload,
    videoUpload,
    fileUpload,
    peopleGrey,
    peopleBlue,
    callGrey,
    chatGrey,
    chatBlue,
    groupGrey,
    groupBlue,
    moreGrey,
    moreBlue,
    notificationBlack,
    privacyBlack,
    chatBlack,
    helpBlack,
    reportBlack
};