import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getLoggedinUser, login, createUser, getLocalUser } from '../../helpers/index';
import CometChatUnified from '../../lib/CometChat/components/CometChatUnified';
import "./styles.scss";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.userID = getLocalUser(0);
        this.state = {
            user: null,
            isModalShown: false
        };
        this.onHide = this.onHide.bind(this);
    }

    render() {
        const { user, isModalShown } = this.state;
        let renderCometChatUnified;

        if (user) {
            renderCometChatUnified = (<CometChatUnified />);
        }

        return (
            <main id="main">
                <Modal
                    id="modal-chat-container"
                    show={isModalShown}
                    size="lg"
                    centered
                    onHide={this.onHide}
                    animation={false}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {renderCometChatUnified}
                    </Modal.Body>
                </Modal>

                <Button id="ct-button" variant="primary" size="sm" onClick={this.onHide}>Chat</Button>
            </main>
        );
    }

    onHide() {
        this.setState({ isModalShown: !this.state.isModalShown });
    }

    componentDidMount() {
        const _this = this;

        if (_this.userID) {
            getLoggedinUser((user) => {
                if (user) {
                    _this.setState({ user });
                } else {
                    login(_this.userID, (user) => {
                        if (user) {
                            _this.setState({ user });
                        } else {
                            createUser(_this.userID, (user) => {
                                if (user) {
                                    login(user.uid, (user) => {
                                        _this.setState({ user });
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    }
}

export {
    Main as default
};